import Vue from 'vue';

import VueRouter from 'vue-router';

let HttpUrl = window.location.href;
let URL_PATH = '';
if (HttpUrl.indexOf('puer2') === -1 && HttpUrl.indexOf('puer') !== -1) {
    URL_PATH = 'puer'
} else if (HttpUrl.indexOf('puer2') !== -1) {
    URL_PATH = 'puer2'
} else if (HttpUrl.indexOf('tianjin') !== -1) {
    URL_PATH = 'tianjin'
} else if (HttpUrl.indexOf('xsbn') !== -1) {
    URL_PATH = 'xsbn'
}
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};
Vue.use(VueRouter);
let routes = [];
if (URL_PATH === 'xsbn') {
    routes = [
        {
            path: '/xsbn/',
            name: 'index',
            meta: {
                requireAuth: false,
                title: '西双版纳惠民保',
            },
            component: () => import('../views/page_xsbn/index.vue')
        },
        {
            path: '/xsbn/home',
            name: 'home',
            meta: {
                requireAuth: false,
                title: '西双版纳惠民保',
            },
            component: () => import('../views/page_xsbn/index.vue')
        },
        {
            path: '/xsbn/order_list',
            name: 'order_list',
            meta: {
                keepAlive: false,
                requireAuth: true,
                title: '订单查询',
            },
            component: () => import('../views/page_xsbn/order_list.vue')
        },
        {
            path: '/xsbn/insure_page',
            name: 'insure_page',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_xsbn/insure_page.vue')
        },
        {
            path: '/xsbn/pay_select',
            name: 'pay_select',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_xsbn/pay_select.vue')
        }
    ];
} else if (URL_PATH === 'tianjin') {
    routes = [
        {
            path: '/tianjin/',
            name: 'index',
            meta: {
                requireAuth: false,
                title: '人民普惠保（天津）',
            },
            component: () => import('../views/page_tj/index.vue')
        },
        {
            path: '/tianjin/home',
            name: 'home',
            meta: {
                requireAuth: false,
                title: '人民普惠保（天津）',
            },
            component: () => import('../views/page_tj/index.vue')
        },
        {
            path: '/tianjin/order_list',
            name: 'order_list',
            meta: {
                keepAlive: false,
                requireAuth: true,
                title: '订单查询',
            },
            component: () => import('../views/page_tj/order_list.vue')
        },
        {
            path: '/tianjin/insure_page',
            name: 'insure_page',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_tj/insure_page.vue')
        },
        {
            path: '/tianjin/pay_select',
            name: 'pay_select',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_tj/pay_select.vue')
        }
    ];
} else if (URL_PATH === 'puer') {
    routes = [
        {
            path: '/puer/',
            name: 'index',
            meta: {
                requireAuth: false,
                title: '人民普惠保普洱',
            },
            component: () => import('../views/page_pr/index.vue')
        },
        {
            path: '/puer/home',
            name: 'home',
            meta: {
                requireAuth: false,
                title: '人民普惠保普洱',
            },
            component: () => import('../views/page_pr/index.vue')
        },
        {
            path: '/puer/order_list',
            name: 'order_list',
            meta: {
                keepAlive: false,
                requireAuth: true,
                title: '订单查询',
            },
            component: () => import('../views/page_pr/order_list.vue')
        },
        {
            path: '/puer/insure_page',
            name: 'insure_page',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_pr/insure_page.vue')
        },
        {
            path: '/puer/pay_select',
            name: 'pay_select',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_pr/pay_select.vue')
        }
    ];
} else if (URL_PATH === 'puer2') {
    routes = [
        {
            path: '/puer2/',
            name: 'index',
            meta: {
                requireAuth: false,
                title: '人民普惠保普洱',
            },
            component: () => import('../views/page_pr2/index.vue')
        },
        {
            path: '/puer2/home',
            name: 'home',
            meta: {
                requireAuth: false,
                title: '人民普惠保普洱',
            },
            component: () => import('../views/page_pr2/index.vue')
        },
        {
            path: '/puer2/order_list',
            name: 'order_list',
            meta: {
                keepAlive: false,
                requireAuth: true,
                title: '订单查询',
            },
            component: () => import('../views/page_pr2/order_list.vue')
        },
        {
            path: '/puer2/insure_page',
            name: 'insure_page',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_pr2/insure_page.vue')
        },
        {
            path: '/puer2/pay_select',
            name: 'pay_select',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_pr2/pay_select.vue')
        }
    ];
} else {
    routes = [
        {
            path: '/',
            name: 'index',
            meta: {
                requireAuth: false,
                title: '广福保',
            },
            component: () => import('../views/page_gfb/index.vue')
        },
        {
            path: '/home',
            name: 'home',
            meta: {
                requireAuth: false,
                title: '广福保',
            },
            component: () => import('../views/page_gfb/index.vue')
        },
        {
            path: '/order_list',
            name: 'order_list',
            meta: {
                keepAlive: false,
                requireAuth: true,
                title: '订单查询',
            },
            component: () => import('../views/page_gfb/order_list.vue')
        },
        {
            path: '/insure_page',
            name: 'insure_page',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_gfb/insure_page.vue')
        },
        {
            path: '/pay_select',
            name: 'pay_select',
            meta: {
                requireAuth: true,
                title: '信息填写',
            },
            component: () => import('../views/page_gfb/pay_select.vue')
        }
    ];
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
export default router
