<template>
	<div>
		<el-dialog :visible.sync="sign_in_dialog" width="1000px" append-to-body :show-close="false" :close-on-press-escape="false" :lock-scroll="false" :before-close="singn_close">
			<div class="sing_in_dialog_div">
				<img class="login_img" src="https://oss.nuohuipay.cn/ClientProject/xsbn/images/1665290864290.png">
				<div class="form_sumber_div">
					<div class="title_top PingFangMedium">企业登录</div>
					<div class="input_list_div">
						<div class="ingput_list_class">
							<img class="icon_tel" src="https://oss.nuohuipay.cn/ClientProject/mobile/guangfubao/images/1646217201564.png">
							<input class="input_class" v-model="mobileValue" type="text" placeholder="请输入您投保时填写的联系电话">
						</div>
						<div class="code_input_div">
							<input class="input_class" v-model="mobileCode" type="text" placeholder="请输入验证码">
							<button class="code_button" v-if="sendAuthCode" @click="getAuthCode">获取验证码</button>
							<button class="code_button" v-if="!sendAuthCode">获取验证码 {{auth_time}}S</button>
						</div>
						<div class="erro_tip">{{erro_tip}}</div>
						<button class="sumber_login PingFangMedium" @click="login_sumber">登&nbsp;&nbsp;&nbsp;录</button>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
    import app from '../../../assets/api/apiurl'
    export default {
        name: "login",
	    data(){
            return{
                mobileValue: '',//手机号
                mobileCode: '',//验证码
                sendAuthCode: true,
                auth_time: 0,
                erro_tip: '',//错误提示
            }
	    },
        props: {
            sign_in_dialog: {
                type: Boolean,
                default: false
            }
        },
	    methods:{
            singn_close(){
                this.$emit('sign_in_emit', false);
            },
            getAuthCode() {//获取验证码
                let mobile = this.mobileValue;
                this.erro_tip = '';
                if (mobile === '') {
                    this.erro_tip = '手机号码不能为空';
                    return;
                }
                if (!(/^1[3456789]\d{9}$/.test(mobile))) {
                    this.erro_tip = '手机号码格式错误';
                    return;
                }
                this.$ajax.post(app.API_URL + '/user/sendSMSByMobile', {
                    mobile: mobile
                }).then((res) => {
                    if (res.data.code === 1) {
                        this.sendAuthCode = false;
                        this.auth_time = 60;
                        this.$message.success('发送成功');
                        let auth_timetimer = setInterval(() => {
                            this.auth_time--;
                            if (this.auth_time <= 0) {
                                this.sendAuthCode = true;
                                clearInterval(auth_timetimer);
                            }
                        }, 1000);
                    }
                });
            },
            login_sumber() {//登录
                this.erro_tip = '';
                let mobile = this.mobileValue;
                let code = this.mobileCode;
                if (mobile === '') {
                    this.erro_tip = '手机号码不能为空';
                    return;
                }
                if (!(/^1[3456789]\d{9}$/.test(mobile))) {
                    this.erro_tip = '手机号码格式错误';
                    return;
                }
                if (code === '') {
                    this.erro_tip = '验证码不能为空';
                    return;
                }
                this.$ajax.post(app.API_URL + '/login/mobile', {
                    mobile: mobile,
                    code: code
                }).then((res) => {
                    if (res.data.code === 1) {
                        localStorage.setItem('token', res.data.data);
                        localStorage.setItem('mobile', mobile);
                        this.$emit('sign_in_emit', false);
                        this.$message.success('登录成功');
                        let router_nav = localStorage.getItem("router_nav");
                        let is_home_router = localStorage.getItem("is_home_router");
                        let is_reload = localStorage.getItem("is_reload");
                        if (router_nav === '1') {
                            this.$router.push('/xsbn/order_list');
                        }
                        if (is_home_router === 'Y') {
                            localStorage.setItem('router_nav', '1');
                            localStorage.removeItem('is_home_router');
                            this.$router.push('/xsbn/order_list');
                        }
                        if (is_reload === 'Y') {
                            localStorage.removeItem('is_reload');
                            window.location.reload();
                        }
                    }
                });
            },
	    }
    }
</script>

<style lang="less" scoped>
	.sing_in_dialog_div {
		display: flex;
		justify-content: flex-start;
		.login_img {
			width: 440px;
			height: 656px;
			display: block;
		}
		.form_sumber_div {
			flex: 1;
			padding: 71px 56px 118px;
			.title_top {
				color: #252631;
				font-size: 28px;
				font-weight: 500;
			}
			.input_list_div {
				// margin-top: 10px;
				.ingput_list_class {
					border-radius: 4px;
					margin-top: 44px;
					border: 1px solid #e2e2e2;
					background: #f6f6f8;
					padding: 16px 22px;
					width: 100%;
					display: flex;
					justify-content: flex-start;
					box-sizing: border-box;
					.icon_tel {
						width: 24px;
						// margin-top: 6px;
						height: 33px;
						display: block;
						margin-right: 16px;
					}
					.input_class {
						border: none;
						width: 350px;
						height: 33px;
						background: none;
						color: #333333;
						font-size: 20px;
					}
					.input_class::-webkit-input-placeholder {
						color: #bababa !important;
					}
					.input_class::-moz-placeholder {
						/* Mozilla Firefox 19+ */
						color: #bababa !important;
					}
					.input_class:-moz-placeholder {
						/* Mozilla Firefox 4 to 18 */
						color: #bababa !important;
					}
					.input_class:-ms-input-placeholder {
						/* Internet Explorer 10-11 */
						color: #bababa !important;
					}
					.input_class:focus {
						outline: none;
						border: none;
						box-shadow: none;
					}
				}
				.erro_tip {
					color: #a72c1c;
					font-weight: 500;
					font-size: 16px;
					margin-top: 20px;
					height: 30px;
				}
				.sumber_login {
					background: #a72c1c;
					color: #fff;
					margin-top: 100px;
					width: 100%;
					height: 60px;
					border-radius: 8px;
					font-size: 26px;
					border: none;
					cursor: pointer;
				}
				.code_input_div {
					border-radius: 4px;
					margin-top: 34px;
					border: 1px solid #e2e2e2;
					background: #f6f6f8;
					padding: 11px 11px 11px 22px;
					width: 100%;
					display: flex;
					justify-content: space-between;
					box-sizing: border-box;
					.code_button {
						width: 120px;
						color: #fff;
						font-size: 14px;
						height: 44px;
						background: #a72c1c;
						border-radius: 4px;
						border: none;
						cursor: pointer;
					}
					.input_class {
						border: none;
						width: 150px;
						background: none;
						color: #333333;
						font-size: 20px;
					}
					.input_class::-webkit-input-placeholder {
						color: #bababa !important;
					}
					.input_class::-moz-placeholder {
						/* Mozilla Firefox 19+ */
						color: #bababa !important;
					}
					.input_class:-moz-placeholder {
						/* Mozilla Firefox 4 to 18 */
						color: #bababa !important;
					}
					.input_class:-ms-input-placeholder {
						/* Internet Explorer 10-11 */
						color: #bababa !important;
					}
					.input_class:focus {
						outline: none;
						border: none;
						box-shadow: none;
					}
				}
			}
		}
	}
</style>