<template>
    <div class="head_nav_div">
        <div class="top_bady">
            <div class="left_navtop">
                <img class="log_img" @click="clicknav(0)" src="https://oss.nuohuipay.cn/ClientProject/mobile/tianjin/images/1652851483354.png">
                <div class="nav_top_list">
                    <div class="left_title_name" :class="router_nav===0?'click_router':''" @click="clicknav(0)">产品介绍</div>
                    <div :class="router_nav===1?'click_router':''" @click="clicknav(1)">订单查询</div>
                </div>
            </div>
            <div class="right_user_tel">
                <img class="tel_icon" src="https://oss.nuohuipay.cn/ClientProject/mobile/guangfubao/images/1646201636878.png">
                <div class="telphone_in">官方客服电话：95502</div>
                <div class="user_login">
                    <div class="user_login_butom">
                        <button class="no_login" v-if="user_token === ''" @click="login_open">您好，请登录</button>
                        <el-dropdown v-else>
                            <span class="el-dropdown-link user_conter_num">
                                {{user_mobile}}
                                <img class="iconaee" src="https://oss.nuohuipay.cn/ClientProject/mobile/guangfubao/images/1646206417134.png">
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item icon="fa fa-sign-in" @click.native="sign_in_out">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>
		<login :sign_in_dialog="sign_in_dialog" @sign_in_emit="sign_in_emit"></login>
    </div>
</template>

<script>
import app from '../../../assets/api/apiurl'
import login from './login'
export default {
    name: "head_nav",
    components:{
        login
	},
    data() {
        return {
            router_nav: 0,
            user_token: '',//token
            user_mobile: '',//手机号
            sign_in_dialog:false,
        }
    },
    watch:{
        $route(to,from){
            if(to.path === '/tianjin/'){
                this.router_nav = 0;
            }
            if(to.path === '/tianjin/home'){
                this.router_nav = 0;
                let is_Login = localStorage.getItem("is_login");
                if (is_Login === 'Y'){
                    this.user_token = '';
                    this.user_mobile = '';
                    localStorage.removeItem('token');
                    localStorage.removeItem('mobile');
                    this.login_open();
                }
            }
            if(to.path === '/tianjin/order_list'){
                this.router_nav = 1;
            }
        }
    },
    mounted() {
        let token = localStorage.getItem("token");
        let mobile = localStorage.getItem("mobile");
        let router_nav = localStorage.getItem("router_nav");
        if (token) {
            this.user_token = token;
        }
        if (mobile) {
            this.user_mobile = mobile;
        }
        if (router_nav) {
            this.router_nav = Number(router_nav);
        }
    },
    methods: {
        sign_in_emit() {
            this.sign_in_dialog = false;
            localStorage.removeItem('is_login');
            let token = localStorage.getItem("token");
            let mobile = localStorage.getItem("mobile");
            let router_nav = localStorage.getItem("router_nav");
            if (token) {
                this.user_token = token;
            }
            if (mobile) {
                this.user_mobile = mobile;
            }
            if (router_nav) {
                this.router_nav = Number(router_nav);
            }
        },
        sign_in_out() {//退出
            this.$ajax.post(app.API_URL + '/login/out', {
                token: this.user_token
            }).then(res => {
                localStorage.removeItem('token');
                localStorage.removeItem('mobile');
                this.user_token = '';
                this.user_mobile = '';
                this.router_nav = 0;
                localStorage.setItem('router_nav', 0);
                this.$router.push('/tianjin/home');
            })
        },
        login_open(){
            this.sign_in_dialog = true;
        },
        clicknav(val) {//切换
            if (val !== this.router_nav) {
                if (val === 0) {
                    this.router_nav = val;
                    localStorage.setItem('router_nav', val);
                    this.$router.push('/tianjin/home')
                }
                if (val === 1) {
                    if (this.user_token === '') {
                        this.sign_in_dialog = true;
                        localStorage.setItem('is_home_router', 'Y');
                        return;
                    } else {
                        this.router_nav = val;
                        localStorage.setItem('router_nav', val);
	                    this.$router.push('/tianjin/order_list')
                    }
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
.head_nav_div {
    min-width: 1200px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    padding: 0 100px;
    z-index: 10;
    .top_bady {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left_navtop {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .log_img {
                cursor: pointer;
                padding: 15px 0;
                width: 50px;
                height: 50px;
                display: block;
            }
            .nav_top_list {
                margin-left: 130px;
                display: flex;
                justify-content: space-between;
                line-height: 76px;
                div {
                    color: #424242;
                    font-size: 16px;
                    cursor: pointer;
                }
                .left_title_name {
                    margin-right: 70px;
                }
                .click_router {
                    color: #a72c1c;
                    border-bottom: 4px solid #a72c1c;
                }
            }
        }
        .right_user_tel {
            display: flex;
            justify-content: space-between;
            .tel_icon {
                width: 32px;
                height: 32px;
                padding: 24px 0;
            }
            .telphone_in {
                font-size: 16px;
                color: #a72c1c;
                margin-left: 20px;
                line-height: 80px;
            }
            .user_login {
                padding: 20px 0;
                margin-left: 30px;
                .user_login_butom {
                    border-left: 1px solid #d8d8d8;
                    height: 40px;
                    padding-left: 30px;
                    .no_login {
                        height: 30px;
                        width: 130px;
                        margin-top: 5px;
                        line-height: 22px;
                        color: #333;
                        font-size: 14px;
                        border-radius: 15px;
                        background: #fff;
                        border: 1px solid #333333;
                        cursor: pointer;
                    }
                    .user_conter_num {
                        line-height: 40px;
                        font-size: 16px;
                        color: #333;
                        .iconaee {
                            margin-left: 5px;
                            width: 12px;
                            height: 8px;
                        }
                    }
                }
            }
        }
    }
}
</style>