<template>
	<div>
		<headnav_gfb v-if="is_path === 'page_gfb'"></headnav_gfb>
		<headnav_puer v-if="is_path === 'page_pr'"></headnav_puer>
		<headnav_puer_two v-if="is_path === 'page_pr2'"></headnav_puer_two>
		<headnav_tianjin v-if="is_path === 'page_tj'"></headnav_tianjin>
		<headnav_xsbn v-if="is_path === 'page_xsbn'"></headnav_xsbn>
		<keep-alive>
			<router-view v-if="$route.meta.keepAlive" style="margin-top: 80px; margin-bottom: 60px;"></router-view>
		</keep-alive>
		<router-view v-if="!$route.meta.keepAlive" style="margin-top: 80px; margin-bottom: 60px;"></router-view>
		<div class="bottom_div_icp" v-if="is_path === 'page_xsbn'">
			<span>中兴保险经纪有限公司代销&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;icp备案号：京ICP备16017394号-2</span>
		</div>
		<div class="bottom_div_icp" v-else>
			<span>向日葵保险经纪有限公司代销及代收保费&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ICP备案号：粤ICP备19046842号-2</span>
		</div>
		
	</div>
</template>
<script>
    import headnav_gfb from '/src/views/page_gfb/components/head_nav'
    import headnav_puer from '/src/views/page_pr/components/head_nav'
	import headnav_puer_two from '/src/views/page_pr2/components/head_nav'
    import headnav_tianjin from '/src/views/page_tj/components/head_nav'
	import headnav_xsbn from '/src/views/page_xsbn/components/head_nav'
    export default {
        components: { headnav_gfb,headnav_puer,headnav_tianjin, headnav_xsbn, headnav_puer_two},
	    data(){
            return{
                is_path:'',
            }
	    },
	    mounted(){
            let HttpUrl = window.location.href;
            if ( HttpUrl.indexOf('xsbn/') !== -1) {
                this.is_path = 'page_xsbn';
            }else if ( HttpUrl.indexOf('puer2/') === -1 && HttpUrl.indexOf('puer/') !== -1) {
                this.is_path = 'page_pr';
            }else if ( HttpUrl.indexOf('puer2/') !== -1) {
                this.is_path = 'page_pr2';
            }else if ( HttpUrl.indexOf('tianjin/') !== -1) {
                this.is_path = 'page_tj';
            }else {
                this.is_path = 'page_gfb';
            }
	    }
    }
</script>
<style lang="less">
	@import "./assets/font/font.css";
	.PingFangMedium {
		font-family: PingFangMedium;
	}
	.el-dialog__header {
		padding: 0 !important;
	}
	.el-dialog {
		border-radius: 12px !important;
	}
	.el-dialog__body {
		padding: 0 !important;
	}
	body {
		min-width: 1220px;
		overflow-x: auto;
		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}
		&::-webkit-scrollbar-track {
			background: #fff;
			border-radius: 2px;
		}
		&::-webkit-scrollbar-thumb {
			background: #a72c1c;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #a72c1c;
		}
		&::-webkit-scrollbar-corner {
			background: #a72c1c;
		}
	}
	* {
		margin: 0;
		padding: 0;
	}
	body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	html,
	p {
		margin: 0;
		padding: 0;
	}
	body,
	html {
		background: #f4f4f4;
	}
	.bottom_div_icp {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: #881a0b;
		text-align: center;
		height: 60px;
		z-index: 10;
		span {
			color: #ffffff;
			line-height: 60px;
			opacity: 0.8;
			font-size: 16px;
		}
	}
</style>
