let HttpUrl = window.location.href;
let proto = window.location.protocol;
let API_URL = '';//请求url
let OSS_URL = 'https://oss.nuohuipay.cn/';//oss
let PRODUCT_ID = '';
if (HttpUrl.indexOf('localhost') !== -1 || HttpUrl.indexOf('gfb.nuohuipay.cn') !== -1) {
    API_URL = proto + '//apix.nuohuipay.cn/api/gfb/v1';// 测试
    if (HttpUrl.indexOf('puer2/') === -1 && HttpUrl.indexOf('puer/') !== -1) {
        // 普洱测试
        PRODUCT_ID = '98';
    }else if (HttpUrl.indexOf('puer2/') !== -1) {
        // 普洱测试（二期）
        PRODUCT_ID = '104';
    }else if (HttpUrl.indexOf('tianjin/') !== -1) {
        // 天津测试
        PRODUCT_ID = '99';
    }else if (HttpUrl.indexOf('xsbn/') !== -1) {
        // 西双版纳测试
        PRODUCT_ID = '102';
    }else {
        // 广福保测试
        PRODUCT_ID = '96';
    }
}
if (HttpUrl.indexOf('gfb.nuohuipay.com') !== -1) {
    API_URL = proto + '//api.nuohuipay.com/api/gfb/v1';// 正式
    if (HttpUrl.indexOf('puer2/') === -1 && HttpUrl.indexOf('puer/') !== -1) {
        // 普洱正式
        PRODUCT_ID = '25';
    }else if (HttpUrl.indexOf('puer2/') !== -1) {
        // 普洱正式（二期）
        PRODUCT_ID = '30';
    }else if (HttpUrl.indexOf('tianjin/') !== -1) {
        // 天津正式
        PRODUCT_ID = '26';
    }else if (HttpUrl.indexOf('xsbn/') !== -1) {
        // 西双版纳正式
        PRODUCT_ID = '29';
    }else {
        // 广福保正式
        PRODUCT_ID = '23';
    }
}
// 广福保疾病数据
let datalist = datalist = [
    { drug: '泰瑞沙', illness: '肺癌' },
    { drug: '凯美纳', illness: '肺癌' },
    { drug: '赛可瑞', illness: '肺癌' },
    { drug: '吉泰瑞', illness: '肺癌' },
    { drug: '特罗凯', illness: '肺癌' },
    { drug: '伊瑞可', illness: '肺癌' },
    { drug: '艾坦', illness: '胃癌' },
    { drug: '赫赛汀', illness: '乳腺癌、胃癌' },
    { drug: '泰立沙', illness: '乳腺癌' },
    { drug: '芙仕得', illness: '乳腺癌' },
    { drug: '艾森特', illness: '前列腺癌' },
    { drug: '多吉美', illness: '肝癌、甲状腺癌' },
    { drug: '拜万戈', illness: '肝癌、结直肠癌、胃肠间质瘤' },
    { drug: '泰欣生', illness: '鼻咽癌' },
    { drug: '达希纳', illness: '白血病' },
    { drug: '依尼舒', illness: '白血病' },
    { drug: '格列卫', illness: '白血病、胃肠间质瘤' },
    { drug: '索坦', illness: '肾细胞癌、胃肠间质瘤' },
    { drug: '维全特', illness: '肾细胞癌' },
    { drug: '飞尼妥', illness: '肾细胞癌' },
    { drug: '爱必妥', illness: '结直肠癌' },
    { drug: '爱谱沙', illness: '非霍奇金淋巴瘤' },
    { drug: '亿珂', illness: '非霍奇金淋巴瘤' },
    { drug: '汉利康', illness: '非霍奇金淋巴瘤' },
    { drug: '齐普乐', illness: '多发性骨髓瘤、非霍奇金淋巴瘤' },
    { drug: '瑞复美', illness: '多发性骨髓瘤' },
    { drug: '恩莱瑞', illness: '多发性骨髓瘤' },
];

export default {
    API_URL,
    OSS_URL,
    datalist,
    PRODUCT_ID
}
