import 'font-awesome/css/font-awesome.min.css';
// import 'element-ui/lib/theme-chalk/index.css';
import './assets/element_color/index.css';

import Vue from 'vue';

import Axios from 'axios';
import ElementUI, { Message } from 'element-ui';

import App from './App.vue';
import router from './router';

Vue.use(ElementUI);
Vue.config.productionTip = false;
//将axios改写为Vue的原形属性
Vue.prototype.$ajax = Axios;
let HttpUrl = window.location.href;
if (HttpUrl.indexOf('localhost') !== -1 || HttpUrl.indexOf('gfb.nuohuipay.cn') !== -1) {
    Message.warning('当前为内部测试环境，请勿向外分享');
}
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next();
});
// 请求拦截器
Axios.interceptors.request.use(req => {
    let token = localStorage.getItem("token");
    if (token) {
        req.headers['token'] = token;
    }
    if (req.data === '' || req.data === null || req.data === undefined) {
        if ( HttpUrl.indexOf('puer2/') === -1 && HttpUrl.indexOf('puer/') !== -1) {
            req.params['pcode'] = 'puer';
        }
        if ( HttpUrl.indexOf('puer2/') !== -1) {
            req.params['pcode'] = 'puer2';
        }
        if (HttpUrl.indexOf('tianjin/') !== -1) {
            req.params['pcode'] = 'tianjin';
        }
        if (HttpUrl.indexOf('xsbn/') !== -1) {
            req.params['pcode'] = 'xsbn';
        }
        return req;
    } else {
        if (req.data.append === '' || req.data.append === null || req.data.append === undefined) {
            if ( HttpUrl.indexOf('puer2/') === -1 && HttpUrl.indexOf('puer/') !== -1) {
                req.data['pcode'] = 'puer';
            }
            if ( HttpUrl.indexOf('puer2/') !== -1) {
                req.data['pcode'] = 'puer2';
            }
            if ( HttpUrl.indexOf('tianjin/') !== -1) {
                req.data['pcode'] = 'tianjin';
            }
            if (HttpUrl.indexOf('xsbn/') !== -1) {
                req.data['pcode'] = 'xsbn';
            }
            return req;
        } else {
            if ( HttpUrl.indexOf('puer2/') === -1 && HttpUrl.indexOf('puer/') !== -1) {
                req.data.append("pcode", 'puer');
            }
            if ( HttpUrl.indexOf('puer2/') !== -1) {
                req.data.append("pcode", 'puer2');
            }
            if ( HttpUrl.indexOf('tianjin/') !== -1) {
                req.data.append("pcode", 'tianjin');
            }
            if (HttpUrl.indexOf('xsbn/') !== -1) {
                req.data.append("pcode", 'xsbn');
            }
            return req;
        }
    }
    return req;
}, e => {
    // 对请求错误做些什么
    return Promise.reject(e)
});
Axios.interceptors.response.use(response => {
    // 对响应数据做点什么
    if (response.data.code !== 1) {
        if (response.data.code === 400 || response.data.code === 401) {
            Message.error('未登录，请重新登录');
            if ( HttpUrl.indexOf('puer2/') == -1 && HttpUrl.indexOf('puer/') !== -1) {
                localStorage.setItem('is_login','Y');
                router.push('/puer/home')
            }else if ( HttpUrl.indexOf('puer2/') !== -1) {
                localStorage.setItem('is_login','Y');
                router.push('/puer2/home')
            }else if(HttpUrl.indexOf('tianjin/') !== -1){
                localStorage.setItem('is_login','Y');
                router.push('/tianjin/home')
            }else if(HttpUrl.indexOf('xsbn/') !== -1){
                localStorage.setItem('is_login','Y');
                router.push('/xsbn/home')
            }else {
                localStorage.setItem('is_login','Y');
                router.push('/home')
            }
        } else {
            if (response.data.msg && typeof response.data.msg == 'object') {
                return Promise.reject(response)
            }else {
                Message.error(response.data.msg);
            }
            
        }
    }
    return response
}, e => {
    // 对响应错误做点什么
    return Promise.reject(e)
});
new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
